<template>
  <div class="-mypage">
    <div class="c_main -bg-gray -pad-btm">
      <div class="inner">
        <nav class="c_pnkz">
          <ul class="c_pnkz_list">
            <li>
              <a class="c_pointer" @click="$router.push({name:'Home'})">ホーム</a>
            </li>
            <li>
              <a class="c_pointer" @click="$router.push({name:'Mypage'})">マイページ</a>
            </li>
            <li>お問い合わせ</li>
          </ul>
        </nav>
        <h1 class="c_main_ttl">お問い合わせ</h1>
        <p class="greeting">
          <em>ようこそ </em>
          <span>{{ $auth.metadata.belongs }}</span>
          <strong>{{ $auth.metadata.fullname }}</strong> 様
        </p>
        <section-chat :isAdmin="false" ref="chat"></section-chat>
      </div>
    </div>
  </div>
</template>

<script>
import SectionChat from "@/components/SectionChat";

export default {
  components: {
    SectionChat
  },
  beforeRouteLeave(to, from, next) {
    this.$refs.chat.webSocketClose();
    next();
  }
};
</script>

<style scoped>
@import "~@/css/mypage.css";
</style>